import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Box, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import AccessibleIconButton from '../Buttons/AccessibleIconButton';
import NonModalMenu from '../Menu/NonModalMenu';
import Tooltip from '../Tooltip/Tooltip';

import menuStyles from '../../assets/jss/components/menuStyle';
import typographyStyle from '../../assets/jss/components/typographyStyle';
import { primaryColor, whiteColor } from '../../assets/jss/shared';

const useMenuStyles = makeStyles(menuStyles);
const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#2f3b4d',
		width: '100%',
		height: '40px',
		minHeight: '40px',
		paddingLeft: '24px',
		paddingRight: '24px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '16px',
			paddingRight: '16px',
		},
		boxSizing: 'border-box',
	},
	container: {
		padding: '0',
		height: '100%',
	},
	button: {
		padding: '8px',
		height: '40px',
		boxSizing: 'border-box',
		borderRadius: '0',
		'&[disabled]': {
			cursor: 'default',
		},
		'& label, & span.button-label': {
			...typographyStyle.toolbar,
			display: 'block',
		},
	},
	label: {
		...typographyStyle.toolbarLabel,
		display: 'block',
		margin: '0 8px',
	},
	separator: {
		backgroundColor: whiteColor,
		width: '1px',
		minWidth: '1px',
		height: '36px',
		margin: '0 8px',
	},
	spacer: {
		flexGrow: '1',
	},
	highlight: {
		backgroundColor: primaryColor[4],
	},
}));

const ToolbarHeader = () => {
	const menuClasses = useMenuStyles();
	const { root, container, button, label, separator, spacer, highlight } = useStyles();
	const contentMaxWidth = useSelector((state) => state.appReducer.contentMaxWidth);
	const toolbarReducer = useSelector((state) => state.toolbarReducer);
	const {
		toolbar: { display, left = {}, right = {} },
	} = toolbarReducer;
	const [activeAnchor, setActiveAnchor] = useState({});
	const { t } = useTranslation();

	const getTool = (tool, index) => {
		const { options, position } = tool.menu || {};
		const useMenu = tool.menu && options && options.length > 0;

		// Don't render these
		if (tool.hidden || tool.overflow || (tool.menu && !useMenu) || (tool.overflowMenu && !tool.menu)) {
			return null;
		}

		if (tool.component) {
			return tool.component;
		}

		// Separator
		if (tool.separator) {
			return <span key={tool.key || tool.id || `separator-${index}`} className={separator} data-cy={tool.dataCy} data-separator />;
		}

		// Spacer
		if (tool.spacer) {
			return <span key={tool.key || tool.id || `spacer-${index}`} className={spacer} data-cy={tool.dataCy} data-spacer />;
		}

		const menuKey = useMenu ? `${tool.key || tool.id}-menu` : undefined;
		let anchor = null;
		if (menuKey && activeAnchor.key === menuKey) {
			({ anchor } = activeAnchor);
		}
		// Ensure that the click event happens only once
		// eslint-disable-next-line no-nested-ternary
		const onClick = useMenu
			? (e) => {
					e.stopPropagation();
					e.preventDefault();
					if (activeAnchor.anchor === e.currentTarget) {
						setActiveAnchor({});
					} else {
						setActiveAnchor({
							key: menuKey,
							anchor: e.currentTarget,
						});
					}
				}
			: tool.onClick
				? (e) => {
						e.stopPropagation();
						e.preventDefault();
						tool.onClick();
					}
				: undefined;

		// Label only with no click event
		if (!onClick) {
			return tool.label && tool.label.length > 0 ? (
				<Tooltip key={tool.key || tool.id} placement="bottom-start" title={tool.tooltipText || ''}>
					<Box id={tool.id} className={label} ml={0} data-cy={tool.dataCy}>
						{tool.label}
					</Box>
				</Tooltip>
			) : null;
		}

		return (
			<React.Fragment key={`${tool.key || tool.id}-container`}>
				<AccessibleIconButton
					id={tool.id}
					key={tool.key || tool.id}
					className={clsx(button, { [highlight]: tool.highlight })}
					color="inherit"
					iconName={tool.icon}
					label={tool.label}
					tooltipText={tool.tooltipText || ''}
					aria-label={tool.ariaLabel || tool.tooltipText || tool.label}
					onClick={onClick}
					dataCy={tool.dataCy}
					badgeProps={tool.badgeProps}
					isHeader
					disabled={tool.disabled}
					displayIconOnRight={tool.id === 'menu-copyAndMove' ? true : false}
					placement={'top'}
				/>
				{useMenu && anchor && (
					<NonModalMenu
						id={`${tool.id}-menu`}
						key={`${tool.key || tool.id}-menu`}
						className={menuClasses.menu}
						anchorEl={anchor}
						keepMounted
						open={Boolean(anchor)}
						onClose={() => {
							setActiveAnchor({});
						}}
						options={options.map((option) => {
							return {
								...option,
								actionFunction: () => {
									if (typeof option.actionFunction === 'function') {
										option.actionFunction();
									}

									setActiveAnchor({}); // Ensure that clicking a menu option will close the menu
								},
							};
						})}
						position={position}
					/>
				)}
			</React.Fragment>
		);
	};

	const getToolbarItems = () => {
		const allTools = (left.tools || []).concat([{ spacer: true }]).concat(right.tools || []);

		// There will be a single overflow menu - Get the options for it
		const overflowTools = allTools
			.filter((tool) => tool.overflow && !tool.hidden)
			.map((tool) => {
				return {
					label: tool.label,
					actionFunction: tool.onClick,
					dataCy: tool.dataCy,
				};
			});

		// Add the overflow menu if needed
		if (overflowTools.length > 0) {
			let overflowMenuTool = allTools.find((tool) => tool.overflowMenu);
			if (!overflowMenuTool) {
				overflowMenuTool = {
					overflowMenu: true,
				};
				allTools.push(overflowMenuTool);
			}
			overflowMenuTool.id = overflowMenuTool.id || 'toolbar-overflow';
			overflowMenuTool.icon = overflowMenuTool.icon || 'more';
			overflowMenuTool.tooltipText = overflowMenuTool.tooltipText || '';
			overflowMenuTool.ariaLabel = overflowMenuTool.ariaLabel || t('menu.options');
			overflowMenuTool.dataCy = overflowMenuTool.dataCy || 'toolbar-overflow';
			overflowMenuTool.menu = {
				id: 'toolbar-overflow-menu',
				position: 'bottom-end',
				options: overflowTools,
			};
		}

		return allTools.map(getTool);
	};

	return display ? (
		<div className={root}>
			<Container className={container} maxWidth={contentMaxWidth}>
				<Box display="flex" alignItems="center" justifyContent="flex-start" height="100%">
					{getToolbarItems()}
				</Box>
			</Container>
		</div>
	) : null;
};

export default ToolbarHeader;
